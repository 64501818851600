import * as THREE from 'three'
import { Canvas, useLoader } from '@react-three/fiber'
import { useEffect, useState, useLayoutEffect, Suspense } from 'react'
import {Environment, Lightformer, useGLTF,useAnimations, ContactShadows, OrbitControls, MeshReflectorMaterial, useTexture, Html, useProgress } from '@react-three/drei'
import { Effects } from './Effects'

export default function App() {
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), { src: '/xx-5-crop.mp4', crossOrigin: 'Anonymous', loop: true, muted: true, playsInline:true  }),
  )

  useEffect(() => void video.play(), [video])

  function Ground() {
    const [floor, normal] = useTexture(['/color.jpeg', '/normal.jpeg'])
    return (
      <mesh rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <planeGeometry args={[20, 20]} />
          <MeshReflectorMaterial
            color={'#f1f1f1'}
            resolution={680}
            mirror={1.0}
            blur={0}
            minDepthThreshold={.9}
            maxDepthThreshold={1.2}
            metalness={0}
            roughness={0.0}
         />
        </mesh>
    )
  }

  function Monolith({ ...props }) {
    const { scene, materials } = useGLTF('/xxr-scene-v1.glb')
    let mono = scene.children

    const [colorMap, normalMap, roughnessMap] = useTexture(['/concrete/Concrete030_1K_Color.jpg', '/concrete/Concrete030_1K_NormalGL.jpg', '/concrete/Concrete030_1K_Roughness.jpg'])

    useLayoutEffect(() => {
      scene.traverse((obj) => {
        if (obj.isMesh) obj.castShadow = obj.receiveShadow = true
      })
    }, [])

    return <group>
        <mesh geometry={mono[0].geometry} {...props} receiveShadow castShadow>
          <meshPhysicalMaterial toneMapped={true} side={THREE.BackSide} >
            <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
          </meshPhysicalMaterial>
        </mesh>
        <mesh geometry={mono[1].geometry} {...props}>
          <meshStandardMaterial 
            toneMapped={true} 
            side={THREE.BackSide} 
            map={colorMap}
            roughnessMap={roughnessMap}
            metalness={0.8}
            normalMap={normalMap}
          />
        </mesh>
      </group>
  }

  function Sophia({ ...props }) {
    const { scene, materials, animations } = useGLTF('/sophia-v1-transformed.glb')
    const { actions } = useAnimations(animations, scene)
    useLayoutEffect(() => {
      materials['rp_sophia_animated_003_mat'].roughness = 1
      materials['rp_sophia_animated_003_mat'].metalness = 1
      actions['Take 001'].play()
      scene.traverse((obj) => {
        if (obj.isMesh) obj.castShadow = obj.receiveShadow = true
      })
    }, [])
    return <primitive object={scene} {...props} />
  }

  function Daddy({ ...props }) {
    const { scene, materials, animations } = useGLTF('/Daddy.glb')
    const { actions } = useAnimations(animations, scene)
    useLayoutEffect(() => {
      console.log(actions, 'materials')
      materials['Material.020'].roughness = 1
      materials['Material.020'].metalness = 1
      actions['Armature|mixamo.com|Layer0.007'].play()
      scene.traverse((obj) => {
        if (obj.isMesh) obj.castShadow = obj.receiveShadow = true
      })
    }, [])
    return <primitive object={scene} {...props} />
  }

  function Loader() {
    const { progress, loaded } = useProgress()
    
    if(loaded){
      //alert("finished")
    }
    return <Html center>Loading...</Html>
  }

  return (
    <Canvas gl={{ logarithmicDepthBuffer: false, antialias: false, physicallyCorrectLights: false, pixelRatio: 1 }} camera={{ position: [0, 10, 20], fov: 35 }}>

      <color attach="background" args={['#2f2f2f']} />
      <fog attach="fog" args={['#2f2f2f', 20, 50]} />
      <hemisphereLight intensity={0.7} position={[0, 10, -5]} />
      {/*
      */}
      <Suspense fallback={<Loader />}>

      <ContactShadows resolution={1024} frames={1} position={[0, -1.16, 0]} scale={15} blur={0.5} opacity={1} far={20} />
        <group scale={[1.25, 1.25, 4]} position={[0, 6.1, -3, ]} rotation={[0, 0, Math.PI]}>
          <Monolith rotation={[-Math.PI*1.5, 0, 0]} position={[0, 2.5, 0]} scale={[3.6, 1., 6.4]}/>
        </group>
        <Sophia scale={0.01} rotation={[0, Math.PI, 0]} position={[-0.5, -1.01, 3]} />
        <Daddy rotation={[0, Math.PI, 0]} position={[1.5, -1.01, 5]} />
        <group position={[0,-1, 0]} scale={10}>
          <Ground />
        </group>
      </Suspense>
      <Environment resolution={512}>
        {/* Ceiling */}
        {/*
        */}

        <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
        <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />

        <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
        <Lightformer intensity={0} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
        <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
        {/* Sides */}
        <Lightformer intensity={1} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
        <Lightformer intensity={1} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />

        {/* Key */}
        <Lightformer form="ring" color="red" intensity={5} scale={2} position={[10, 10, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
      <Effects />
      <OrbitControls enablePan={true} enableZoom={true} minDistance={5} maxDistance={22} minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.2} minAzimuthAngle={ -Math.PI / 2} maxAzimuthAngle={Math.PI / 2} panSpeed={0.2} zoomSpeed={0.5} />
    </Canvas>
  )
}
